<template>
  <el-form
    :model="ruleForm"
    :rules="rules"
    ref="ruleForm"
    label-width="80px"
    class="demo-ruleForm"
  >
    <el-form-item label="名称：" prop="name">
      <el-input
        v-model="ruleForm.name"
        placeholder="请输入角色名称"
        @input="handleOnKeyup('name')"
      ></el-input>
    </el-form-item>
    <el-form-item label="说明：" prop="remark">
      <el-input
        v-model="ruleForm.remark"
        placeholder="请输入角色说明"
        @input="handleOnKeyup('remark')"
      ></el-input>
    </el-form-item>
    <div class="btn-boxs">
      <el-button class="btns" type="primary" @click="submitForm('ruleForm')"
        >确定</el-button
      >
      <el-button @click="handleClose">取消</el-button>
    </div>
  </el-form>
</template>

<script>
export default {
  props: {
    handleClose: Function,
    getRoleInsert: Function,
  },
  name: "AddRole",
  data() {
    return {
      ruleForm: {},
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        remark: [{ required: true, message: "请输入说明", trigger: "blur" }],
      },
    };
  },
  methods: {
    handleOnKeyup(type) {
      console.log(type);
      this.ruleForm[type] = this.handleKeyup(this.ruleForm[type]);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.getRoleInsert(this.ruleForm);
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
